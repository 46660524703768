import React, { useEffect, useRef, useState } from "react";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import CustomInput from "../../../../components/CustomInput";
import axios from "axios";
import { isDevelop } from "../../../../App";
import { $api } from "../../../../api/api";

const libraries = ["places"];

const MapAutocomplete = ({
  onAddressSelected,
  value,
  style,
  geocoding,
  hasImportant,
}) => {
  const [place, setPlace] = useState(value);
  const inputRef = useRef();

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: $api("google_map_production"),
    libraries,
  });

  const onPlacesChanged = () => {
    const address = inputRef.current.getPlaces();

    if (!address || !address[0]) return;

    const { geometry, address_components, formatted_address, url } = address[0];
    const longitude = String(geometry?.location?.lng()?.toFixed(6));
    const latitude = String(geometry?.location?.lat()?.toFixed(6));

    const titles = {
      country: "",
      city: "",
      others: "",
    };
    address_components.forEach((component) => {
      if (component.types.includes("locality")) {
        titles.city = component.long_name;
      } else if (component.types.includes("country")) {
        titles.country = component.long_name;
      } else {
        titles.others = component.long_name;
      }
    });

    const cityFromAddress = formatted_address.replace(/[\s,]+/g, "");
    setPlace(formatted_address);

    onAddressSelected({
      address,
      formatted_address,
      city: titles.city || cityFromAddress,
      country: titles.country,
      url,
      latitude,
      longitude,
    });
  };

  useEffect(() => {
    if (geocoding && geocoding.lat && geocoding.lng) {
      fetchAdditionalInfo(geocoding.lat, geocoding.lng);
    }
  }, [geocoding]);

  const fetchAdditionalInfo = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json`,
        {
          params: {
            latlng: `${latitude},${longitude}`,
            key: $api("google_map_production"),
          },
        }
      );
      const locationInfo = response.data;
      if (locationInfo.results && locationInfo.results.length > 0) {
        const { geometry, address_components, formatted_address, url } =
          locationInfo?.results[0];
        const longitude = geometry?.location?.lng.toFixed(6);
        const latitude = geometry?.location?.lat.toFixed(6);

        const titles = {
          country: "",
          city: "",
          others: "",
        };
        address_components.forEach((component) => {
          if (component.types.includes("locality")) {
            titles.city = component.long_name;
          } else if (component.types.includes("country")) {
            titles.country = component.long_name;
          } else {
            titles.others = component.long_name;
          }
        });

        const cityFromAddress = formatted_address.replace(/[\s,]+/g, "");
        setPlace(formatted_address);

        onAddressSelected({
          formatted_address,
          city: titles.city || cityFromAddress,
          country: titles.country,
          url: "",
          latitude,
          longitude,
        });
      } else {
        console.log("Yer bulunamadı.");
      }
    } catch (error) {
      console.error("API çağrısında hata:", error);
    }
  };

  useEffect(() => {
    if (place === "" && !place) {
      onAddressSelected(null);
    }
  }, [place]);

  if (loadError) {
    return <span>Xəya baş verdi: {loadError.message}</span>;
  }

  return (
    <div key={isLoaded} style={style}>
      {isLoaded ? (
        <StandaloneSearchBox
          onLoad={(ref) => (inputRef.current = ref)}
          onPlacesChanged={onPlacesChanged}
        >
          <CustomInput
            label={"Ünvan"}
            placeholder={"Axtar"}
            onChange={(value) => setPlace(value)}
            value={place}
            hasImportant={hasImportant}
          />
        </StandaloneSearchBox>
      ) : (
        <span>Yüklənir...</span>
      )}
    </div>
  );
};

export default React.memo(MapAutocomplete);
