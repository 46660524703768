import { toastMsg } from "./swal";

export const generateYouTubeEmbedUrlId = (url) => {
  let regex = /[?&]v=([^#\&\?]*).*/;
  let match = url.match(regex);
  if (!match) {
    regex = /youtu\.be\/([^#\&\?]*).*/;
    match = url.match(regex);
  }
  if (match && match[1]) {
    const videoId = match[1];
    return videoId;
  } else {
    return null;
  }
};

export const getEmploymentType = (type) => {
  let employment_type = "";
  switch (type) {
    case "full_time":
      employment_type = "Əsas iş";
      break;
    case "part_time":
      employment_type = "Əlavə iş";
      break;
    case "internship":
      employment_type = "Təcrübə";
      break;
    case "contract":
      employment_type = "Müqavilə üzrə";
      break;
    case "freelance":
      employment_type = "Sərbəst";
      break;
    case "self_employed":
      employment_type = "Şəxsi işim";
      break;
    case "volunteer":
      employment_type = "Könüllü";
      break;
    default:
      employment_type = "";
      break;
  }
  return employment_type;
};

export const handleCopyToClipboard = async (
  textForCopy,
  successText,
  errorText
) => {
  try {
    if (textForCopy) {
      await navigator.clipboard.writeText(textForCopy);
      toastMsg(successText || "Successfully copied !", "#12db63", "#ffffff");
    }
  } catch (err) {
    console.error("Error on copying", err);
    toastMsg(errorText || "Error on copy !", "#ed4611", "#000000");
  }
};
