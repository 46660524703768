import React from "react";
import dayjs from "dayjs";
import { getEmploymentType } from "../../../../utils/helper";
import ExperienceImage from "./ExperienceImage";

const ProfileExperience = ({ profileData }) => {
  const calcExperience = (start_date, end_date) => {
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);
    const totalMonths =
      (endDate.getFullYear() - startDate.getFullYear()) * 12 +
      (endDate.getMonth() - startDate.getMonth());

    if (totalMonths >= 12) {
      return `${Math.floor(totalMonths / 12)} il`;
    } else {
      return `${totalMonths} ay`;
    }
  };

  return (
    <div className="profileExperience">
      {profileData?.experiences?.map((item, index) => (
        <div className="expCard" key={index}>
          <div className="top">
            <div className="expImg">
              <ExperienceImage
                char={item.company.slice(0, 1) || item.title.slice(0, 1)}
              />
            </div>
            <div className="info">
              <p className="title">{item?.company}</p>
              <div className="subTitle">
                <span>{item?.title}</span>
                <span className="dot">•</span>
                <span>{getEmploymentType(item?.employment_type)}</span>
              </div>
            </div>
          </div>
          <div className="bottom">
            <p className="dates">
              {dayjs(item?.start_date).format("DD.MM.YYYY")} -{" "}
              {item?.still_working_there
                ? "Davam edir"
                : dayjs(item?.end_date).format("DD.MM.YYYY")}
              {item?.start_date &&
                item?.end_date &&
                !item?.still_working_there && (
                  <span>
                    {" "}
                    • {calcExperience(item?.start_date, item?.end_date)}
                  </span>
                )}
            </p>
            <p>{item?.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProfileExperience;
