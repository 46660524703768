import React, { useContext, useState } from "react";
import location from "../../../../assets/images/icons/location.svg";
import PenIcon from "../../../../assets/images/icons/edit-2.svg";
import { t } from "i18next";
import AddSharedUserModal from "../../../../components/AddSharedUserModal";
import { LoginContext } from "../../../../utils/Auth";
import { useNavigate } from "react-router-dom";
import { MainContext } from "../../../../utils/MainContext";
import { handleCopyToClipboard } from "../../../../utils/helper";
import { useTranslation } from "react-i18next";

const ProfileInfo = ({
  profileData,
  onEditProfileInfo,
  showProfileInfoEdit,
  isOwner,
  isProUser,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isUserIn } = useContext(LoginContext);
  const { setRegisterSuccessMsg } = useContext(MainContext);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <div className="profileInfo">
      <div className="title">
        <div>
          <h2>{profileData?.full_name}</h2>
          {isProUser && (
            <p
              className="username"
              onClick={() =>
                handleCopyToClipboard(
                  profileData?.username,
                  t("messages.copy"),
                  t("errors.errorOnCopy")
                )
              }
            >
              @{profileData?.username}
            </p>
          )}
        </div>

        <span className="boxCount">
          {profileData.in_boxes_count} {t("profileCard.saved")}
        </span>
      </div>
      <div className="description">
        <div className="bio">
          <div>
            {isProUser && <p>{profileData?.bio}</p>}
            {isProUser && <p>{profileData?.profession?.name}</p>}
            {/* <p>
              {profileData?.profession?.name}
              {profileData?.profession?.description
                ? ` - ${profileData?.profession?.description}`
                : ""}
            </p> */}
          </div>
          {showProfileInfoEdit && isOwner && isProUser && (
            <div className="editIcon" onClick={() => onEditProfileInfo()}>
              <img src={PenIcon} alt="phone_icon" />
            </div>
          )}
          {!showProfileInfoEdit && !isOwner && (
            <button
              className={`addBtn ${
                profileData?.in_boxes_by_owner?.length !== 0 && "added"
              }`}
              onClick={() => {
                if (profileData?.in_boxes_by_owner?.length === 0) {
                  if (isUserIn) {
                    setModalIsOpen(true);
                  } else {
                    sessionStorage.setItem(
                      "sharedToBox",
                      JSON.stringify(profileData.username)
                    );
                    setRegisterSuccessMsg(
                      "Qutuya əlavə etmək üçün profil yaradın."
                    );
                    navigate("/register");
                  }
                } else {
                  return;
                }
              }}
            >
              {profileData?.in_boxes_by_owner?.length === 0
                ? t("profileCard.add")
                : t("profileCard.added")}
            </button>
          )}
        </div>
        {isProUser && (
          <div className="location">
            {profileData?.address && profileData?.address !== "" && (
              <img src={location} alt="location" />
            )}
            <p>{profileData?.address}</p>
          </div>
        )}
      </div>
      <AddSharedUserModal
        data={profileData}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
      />
    </div>
  );
};

export default ProfileInfo;
