const Checkbox = ({ checked, onChange, disabled }) => {
  return (
    <label className={`customCheckbox ${checked ? "checked" : ""}`}>
      <input
        disabled={disabled}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <span className="checkMark"></span>
    </label>
  );
};

export default Checkbox;
