// ES6 Modules or TypeScript
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

function toastMsg(message, bgColor, clr) {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    background: bgColor,
    color: clr,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  Toast.fire({
    title: message,
  });
}

function confirmSwal(
  title,
  info,
  confirmBtn,
  cancelBtn,
  boxId,
  action,
  grant,
  deny
) {
  Swal.fire({
    title: title,
    text: info,
    showCancelButton: true,
    confirmButtonColor: "#ed4611",
    cancelButtonColor: "#1155ed",
    confirmButtonText: confirmBtn,
    cancelButtonText: cancelBtn,
  }).then((result) => {
    if (result.isConfirmed) {
      action();
      toastMsg(grant, "#ed4611", "#ffffff");
    } else {
      toastMsg(deny, "#1155ed", "#ffffff");
      boxId(null);
    }
  });
}

export { confirmSwal, toastMsg };
