import React, { useState } from "react";
import InfoCircle from "../../../../assets/images/icons/info-circle.svg";
import Bag from "../../../../assets/images/icons/bag-blue.svg";
import PenIcon from "../../../../assets/images/icons/edit-2.svg";
import * as ProfileService from "../../Service/ProfileService";
import Spinner from "../../../../components/Spinner";
import { DEFAULT_IMAGES } from "../../../../constants/constants";

const ProfileEditImage = ({ closeModal, profileData, setProfileData }) => {
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState({
    profile: {
      avatar_b64: "",
    },
  });

  const selectImage = async (e, isLocal) => {
    try {
      if (isLocal) {
        const file = e.target.files[0];
        const base64 = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
        setPreview((prev) => ({
          ...prev,
          profile: {
            ...prev.profile,
            avatar_b64: base64,
          },
        }));
      } else {
        setPreview((prev) => ({
          ...prev,
          profile: {
            ...prev.profile,
            avatar_b64: DEFAULT_IMAGES.avatar_b64,
          },
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeImage = async () => {
    setLoading(true);
    try {
      await ProfileService.userEdit(preview);
      closeModal();
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="editProfilePhoto">
      <div className="editProfileComp">
        <div className="infoArea">
          <img src={InfoCircle} alt="info_icon" />
          <p>Şəkil ölçüsü minimum (120 x 120)px ola bilər.</p>
        </div>
        <div className="profilePhoto">
          <img
            src={
              preview.profile.avatar_b64 ||
              profileData.avatar ||
              DEFAULT_IMAGES.avatar_b64
            }
            alt="avatar"
          />
        </div>
        <span></span>
        <div className="btnGroup">
          <div className="editBtn">
            {!preview.profile.avatar_b64 && (
              <button
                className="removeBtn"
                onClick={async () => {
                  selectImage(null, false);
                  if (preview.profile.avatar_b64) {
                    await changeImage();
                  }
                }}
              >
                <img src={Bag} alt="bag_icon" />
                Sil
              </button>
            )}
            <div className="saveBtn">
              <label
                htmlFor={preview.profile.avatar_b64 ? "" : "imgSelect"}
                onClick={async () => {
                  if (preview.profile.avatar_b64) {
                    await changeImage();
                  }
                }}
              >
                {!preview.profile.avatar_b64 && (
                  <img src={PenIcon} alt="pen_icon" />
                )}{" "}
                {preview.profile.avatar_b64 ? "Tamamla" : "Dəyişdir"}
              </label>
              <input
                id="imgSelect"
                type="file"
                onChange={(e) => selectImage(e, true)}
              />
            </div>
          </div>
          {!preview.profile.avatar_b64 && (
            <div
              className="cancelBtn"
              onClick={(e) => {
                e.preventDefault();
                closeModal();
              }}
            >
              <button className="btnCancel">İmtina</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileEditImage;
